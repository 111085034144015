// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, observable, computed, action } from 'mobx';

// Imports => Constants
import { DATETIME_FORMATS, KEYS } from '@constants';

// Imports => Utilities
import {
  AcSaveState,
  AcFormatDate,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcIsUndefined,
  AcFormatRequestParameters,
  AcFormatErrorMessage,
  AcFormatErrorCode,
  AcSanitize,
} from '@utils';

const _default = {
  open_alerts: [],
  resolved_alerts: [],
  alert: null,
};

const app = {};
export class OperationCenterStore {
  constructor(store) {
    makeObservable(this);

    app.store = store;
  }

  @observable
  open_alerts = null;

  @observable
  resolved_alerts = null;

  @observable
  alert = null;

  @computed
  get current_open_alerts() {
    return this.open_alerts;
  }

  @computed
  get current_resolved_alerts() {
    return this.resolved_alerts;
  }

  @computed
  get current_alert() {
    return this.alert;
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @observable
  loadingOpen = {
    state: false,
    message: null,
  };

  @observable
  loadingResolved = {
    state: false,
    message: null,
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @observable
  order_by = {
    field: null,
    direction: KEYS.ASCENDING,
  };

  @observable
  page = 1;

  @observable
  query = null;

  @action
  setQuery = (input) => {
    const _input = AcSanitize(input);
    if (this.query !== _input) this.query = _input;
  };

  @action
  resetParams = () => {
    this.page = 1;
    this.query = null;
    this.per_page = 0;
    this.order_by = { field: null, direction: KEYS.ASCENDING };
  };

  @computed
  get current_order_by() {
    return this.order_by;
  }

  @action
  setPageNumber = (num) => {
    if (this.page !== num) this.page = num;
  };

  @action
  setPerPage = (num) => {
    if (this.per_page !== num) this.per_page = num;
  };

  @action
  setOrderBy = (field) => {
    let order_by = this.order_by;

    if (order_by.field === field) {
      order_by.direction =
        order_by.direction === KEYS.ASCENDING
          ? KEYS.DESCENDING
          : KEYS.ASCENDING;
    } else order_by.direction = KEYS.ASCENDING;

    order_by.field = field;

    this.order_by = order_by;
    this.setPageNumber(1);
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @computed
  get is_loading_open() {
    return this.loadingOpen.state;
  }

  @computed
  get is_loading_resolved() {
    return this.loadingResolved.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @action
  setLoadingOpen = (state = false, message = null) => {
    this.loadingOpen = {
      state,
      message,
    };
  };

  @action
  setLoadingResolved = (state = false, message = null) => {
    this.loadingResolved = {
      state,
      message,
    };
  };

  @action
  get_open_alerts = (options) => {
    this.setLoadingOpen(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.alerts
      .get_open_alerts(params)
      .then((response) => {
        this.set(KEYS.OPEN_ALERTS, response, true);

        this.setLoadingOpen(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve open alerts',
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
          });

        this.setLoadingOpen(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  get_resolved_alerts = (options) => {
    this.setLoadingResolved(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.alerts
      .get_resolved_alerts(params)
      .then((response) => {
        this.set(KEYS.RESOLVED_ALERTS, response, true);

        this.setLoadingResolved(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve open alerts',
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
          });

        this.setLoadingResolved(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  resolve_open_alert = (id, solution) => {
    this.setBusy(true);

    return app.store.api.alerts
      .resolve_open_alert(id, solution)
      .then(async (response) => {
        await this.set(KEYS.ALERT, response, true);

        app.store.toasters.add({
          variant: 'success',
          description: `Resolved open alert with id: <strong>${id}</strong> successfully.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to resolve open alert with id: <strong>${id}</strong>`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      })
      .finally(() => {
        this.get_resolved_alerts();
        this.get_open_alerts();
      });
  };

  @action
  get_by_id = (id) => {
    this.setLoading(true);

    return app.store.api.alerts
      .get_by_id(id)
      .then((response) => {
        this.set(KEYS.ALERT, response.data, true);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve control unit with id: '<strong>${id}</strong>'`,
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };

  @action
  set = (target, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };
}

export default OperationCenterStore;
