// Imports => Vendor
import axios from 'axios';
// Imports => Dependencies
import { makeObservable, observable, computed, action } from 'mobx';
// Imports => Constants
import { KEYS } from '@constants';
// Imports => Utilities
import { AcSaveState, AcIsUndefined, AcFormatErrorMessage } from '@utils';

let app = {};

export class MyCompanyStore {
  constructor(store) {
    makeObservable(this);
    app.store = store;
    this.setLoading(false);
  }

  @observable
  loading = {
    status: false,
    message: undefined,
  };
  @observable
  my_company = null;

  @computed
  get is_loading() {
    return this.loading.status;
  }

  @computed
  get current_company() {
    return this.my_company;
  }

  @computed
  get current_logo() {
    return this.my_company ? this.my_company.logo : null;
  }

  @action
  setLoading = (state, message) => {
    this.loading = {
      status: state || false,
      message: message || false,
    };
  };

  @action
  setResponse = (response) => {
    this.set(KEYS.MY_COMPANY, response, true);
    this.setLoading(false);
    return response;
  };

  @action
  setError = (message) => (error) => {
    if (!axios.isCancel(error))
      app.store.toasters.add({
        variant: 'error',
        title: message,
        description: AcFormatErrorMessage(error),
      });

    this.setLoading(false);
    if (!axios.isCancel(error)) throw error;
  };

  @action
  getMyCompany = () => {
    this.setLoading(true);

    return app.store.api.myCompany
      .getMyCompany()
      .then(this.setResponse)
      .catch(this.setError(`Failed to retrieve my company'`));
  };

  @action
  updateMyCompany = (_, data) => {
    this.setLoading(true);

    return app.store.api.myCompany
      .updateMyCompany(data)
      .then(this.setResponse)
      .catch(this.setError(`Failed to update company`));
  };

  @action
  addMyCompanyLogo = (data) => {
    this.setLoading(true);

    return app.store.api.myCompany
      .addMyCompanyLogo(data)
      .then(this.setResponse)
      .catch(this.setError(`Failed to add the company logo`));
  };

  @action
  removeMyCompanyLogo = () => {
    this.setLoading(true);

    return app.store.api.myCompany
      .removeMyCompanyLogo()
      .then(this.setResponse)
      .catch(this.setError(`Failed to remove the company logo`));
  };

  @action
  set = (target, value) => {
    if (AcIsUndefined(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    return new Promise((resolve) => {
      this[target] = value;
      AcSaveState(target, value);
      resolve();
    });
  };
}

export default MyCompanyStore;
