// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, toJS, observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS, FILE_TYPES } from '@constants';

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcFormatErrorCode,
  AcIsUndefined,
  AcFormatRequestParameters,
  AcFormatRawDataAsList,
  AcDownloadFile,
} from '@utils';

const _default = {
  options: {},
  pilelists: null,
  pilelist: null,
  transfers: null,
};

let app = {};
let polling_interval = null;

export class PileListStore {
  constructor(store) {
    makeObservable(this);

    app.store = store;
  }

  @observable
  pilelists = _default.pilelists;

  @observable
  pilelist = _default.pilelist;

  @observable
  transfers = _default.transfers;

  @computed
  get current_pilelists() {
    return AcIsSet(this.pilelists) ? toJS(this.pilelists) : null;
  }

  @computed
  get current_pilelist() {
    return AcIsSet(this.pilelist) ? toJS(this.pilelist) : null;
  }

  @computed
  get current_transfers() {
    return AcIsSet(this.transfers) ? toJS(this.transfers) : null;
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @observable
  loading_piles = {
    state: false,
    message: null,
  };

  @computed
  get is_loading_piles() {
    return this.loading_piles.state;
  }

  @action
  setLoadingPiles = (state = false, message = null) => {
    this.loading_piles = {
      state,
      message,
    };
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @computed
  get is_busy() {
    return this.busy.state;
  }

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @observable
  order_by = {
    field: null,
    direction: KEYS.ASCENDING,
  };

  @observable
  page = 1;

  @observable
  query = null;

  @action
  setQuery = (input) => {
    const _input = AcSanitize(input);
    if (this.query !== _input) this.query = _input;
  };

  @action
  resetParams = () => {
    this.page = 1;
    this.query = null;
    this.per_page = 0;
    this.order_by = { field: null, direction: KEYS.ASCENDING };
  };

  @computed
  get current_order_by() {
    return this.order_by;
  }

  @action
  setPageNumber = (num) => {
    if (this.page !== num) this.page = num;
  };

  @action
  setPerPage = (num) => {
    if (this.per_page !== num) this.per_page = num;
  };

  @action
  setOrderBy = (field) => {
    let order_by = this.order_by;

    if (order_by.field === field) {
      order_by.direction =
        order_by.direction === KEYS.ASCENDING
          ? KEYS.DESCENDING
          : KEYS.ASCENDING;
    } else order_by.direction = KEYS.ASCENDING;

    order_by.field = field;

    this.order_by = order_by;
    this.setPageNumber(1);
  };

  @action
  list = (project_id, options) => {
    // piles_per_page
    this.setLoading(true);
    this.setBusy(true);

    const _project_id = AcIsSet(project_id)
      ? project_id
      : app.store.projects.current_project?.id;

    return app.store.api.pilelist
      .list(_project_id)
      .then((response) => {
        this.set(KEYS.PILELISTS, response.data, true);

        if (response.meta && response.meta.current_page)
          this.setPageNumber(response.meta.current_page);

        this.setLoading(false);
        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        console.log('error', error);

        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve pile lists',
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);
        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  get_by_id = (
    project_id,
    pilelist_id,
    options = {},
    including_piles = false
  ) => {
    this.setLoading(true);
    if (including_piles) this.setLoadingPiles(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.pilelist
      .get_by_id(project_id, pilelist_id, params)
      .then((response) => {
        this.set(KEYS.PILELIST, response, true);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve pile list with id <strong>${pilelist_id}</strong>`,
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);
        if (including_piles) this.setLoadingPiles(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  get_piles_in_project = (project_id) => {
    this.setLoadingPiles(true);

    return app.store.api.pilelist
      .get_piles_by_project_id(project_id)
      .then((response) => {
        this.set(KEYS.PILES, response, true);

        this.setLoadingPiles(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve piles with in this current project`,
            description: AcFormatErrorMessage(error),
          });

        this.setLoadingPiles(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  list_sendable_devices = (project_id, pilelist_id) => {
    this.setBusy(true);

    return app.store.api.pilelist
      .list_sendable_devices(project_id, pilelist_id)
      .then((response) => {
        this.setBusy(false);

        return response?.data;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve available devices`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  send_to_control_unit = async (
    project_id,
    pilelist_id,
    pilelist_name,
    devices,
    data
  ) => {
    console.group('send_to_control_unit');
    console.log('project_id', project_id);
    console.log('pilelist_id', pilelist_id);
    console.log('pilelist_name', pilelist_name);
    console.log('devices', devices);
    console.log('data', data);
    console.groupEnd();

    this.setBusy(true);

    let toast = null;

    if (!data.piles?.length) {
      toast = await app.store.toasters.add({
        variant: 'upload',
        title: 'Preparing to send to control unit(s)',
        description: `Sending <strong>all piles</strong> from pile list <strong>${pilelist_name}</strong> to ${
          devices?.length === 1
            ? devices.join(',')
            : `${devices.length} control units`
        }`,
        indeterminate: true,
      });
    } else {
      toast = await app.store.toasters.add({
        variant: 'upload',
        title: 'Preparing to send to control unit(s)',
        description: `Sending <strong>${data.piles?.length} pile${
          data.piles?.length === 1 ? '' : 's'
        }</strong> from pile list <strong>${pilelist_name}</strong> to ${
          devices?.length === 1
            ? devices.join(',')
            : `${devices.length} control units`
        }`,
        indeterminate: true,
      });
    }

    console.log('send_to_control_unit');

    return app.store.api.pilelist
      .send_to_control_unit(project_id, pilelist_id, data)
      .then((response) => {
        this.setBusy(false);

        let description = '';

        if (!data.piles?.length) {
          description = `<strong>All piles</strong> from pile list <strong>${pilelist_name}</strong> have been sent to ${
            devices?.length === 1
              ? devices.join(',')
              : `${devices.length} control units`
          }`;
        } else {
          description = `<strong>${data.piles.length} pile${
            data.piles.length === 1 ? '' : 's'
          }</strong> from pile list <strong>${pilelist_name}</strong> have been sent to ${
            devices?.length === 1
              ? devices.join(',')
              : `${devices.length} control units`
          }`;
        }

        app.store.toasters.update(toast.id, {
          title: 'Send to control unit',
          description,
          variant: 'success',
          indeterminate: false,
        });

        return response?.data;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to sent pile list to control unit(s)`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  delete_pilelist = async (project_id, { id, name }) => {
    // id & name are coming from current_pilelist
    return app.store.api.pilelist
      .delete_pilelist(project_id, id)
      .then(async (response) => {
        this.setQuery(null);
        this.setPageNumber(1);
        await this.reset(KEYS.PILELIST);

        app.store.toasters.add({
          variant: 'success',
          description: `Pile list <strong>${name}</strong> has been deleted.`,
        });

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to delete pile list <strong>${name}</strong>`,
            description: AcFormatErrorMessage(error),
          });

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  create_no_upload = async (project_id, data) => {
    this.setBusy(true);

    const toast = await app.store.toasters.add({
      variant: 'upload',
      title: 'Preparing pile list creation',
      description: `Pile list is being created`,
      indeterminate: true,
    });

    return app.store.api.pilelist
      .store(project_id, data)
      .then(async (response) => {
        await this.list(project_id);

        if (AcIsSet(response?.data?.id)) {
          await this.get_by_id(project_id, response?.data?.id);
        }

        app.store.toasters.update(toast.id, {
          title: 'Pile list creation is complete',
          description: `Pile list <strong>${response?.name}</strong> has been created`,
          variant: 'success',
          indeterminate: false,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.update(toast.id, {
            variant: 'error',
            title: `Failed to create the new pile list`,
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
            indeterminate: false,
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  create_and_upload = async (project_id, data, filename) => {
    this.setBusy(true);

    const toast = await app.store.toasters.add({
      variant: 'upload',
      title: 'Preparing pile list creation',
      description: `Pile list is being created and uploading import file <strong>${filename}</strong>`,
      indeterminate: true,
    });

    return app.store.api.pilelist
      .store(project_id, data)
      .then(async (response) => {
        await this.list(project_id);

        if (AcIsSet(response?.data?.id)) {
          await this.get_by_id(project_id, response?.data?.id);
        }

        app.store.toasters.update(toast.id, {
          title: 'Pile list creation is complete',
          description: `Pile list has been created and <strong>${filename}</strong> has been uploaded`,
          variant: 'success',
          indeterminate: false,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        const isDuplicateError =
          error?.response?.data?.errors?.hash?.[0] ===
          'A pilelist with matching piles already exists';

        if (!isDuplicateError) {
          if (!axios.isCancel(error))
            app.store.toasters.update(toast.id, {
              variant: 'error',
              title: `Failed to create the new pile list`,
              description: AcFormatErrorMessage(error),
              code: AcFormatErrorCode(error),
              indeterminate: false,
            });
        }

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  import_pilelist = async (project_id, pilelist_id, data, filename) => {
    this.setBusy(true);

    const toast = await app.store.toasters.add({
      variant: 'upload',
      title: 'Preparing pile list import',
      description: `Pile list is being imported with file <strong>${filename}</strong>`,
      indeterminate: true,
    });

    return app.store.api.pilelist
      .update(project_id, pilelist_id, data)
      .then(async (response) => {
        if (AcIsSet(response?.data?.id)) {
          await this.get_by_id(project_id, response?.data?.id);
        }

        app.store.toasters.update(toast.id, {
          title: 'Pile list has been updated',
          description: `Pile list has been updated and <strong>${filename}</strong> has been imported`,
          variant: 'success',
          indeterminate: false,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        console.log('import => store => error', error);
        if (!axios.isCancel(error))
          app.store.toasters.update(toast.id, {
            variant: 'error',
            title: `Failed to update the pile list`,
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
            indeterminate: false,
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  download_xls = async (project_id, pilelist_id, params) => {
    this.setBusy(true);

    const toast = await app.store.toasters.add({
      variant: 'download',
      title: 'Preparing download',
      description: `XLS download has been requested`,
      indeterminate: true,
    });

    return app.store.api.pilelist
      .download_xls(project_id, pilelist_id, params)
      .then(async (response) => {
        const { headers, data } = response;
        let file_name = null;

        const contentDisposition = headers?.['content-disposition'];
        const contentType = headers?.['content-type'] || FILE_TYPES.XLS;

        if (
          contentDisposition &&
          contentDisposition.indexOf('attachment') !== -1
        ) {
          let pattern = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = pattern.exec(contentDisposition);
          if (matches != null && matches[1]) {
            file_name = matches[1].replace(/['"]/g, '');
          }
        }

        await AcDownloadFile(data, file_name, contentType);

        app.store.toasters.update(toast.id, {
          title: 'Download started',
          description: `XLS has been downloaded`,
          variant: 'success',
          indeterminate: false,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        app.store.toasters.update(toast.id, {
          title: null,
          description: `Failed to download XLS`,
          code: AcFormatErrorCode(error),
          variant: 'error',
          indeterminate: false,
        });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  download_control_unit_file = async (project_id, pilelist_id, params) => {
    this.setBusy(true);

    const toast = await app.store.toasters.add({
      variant: 'download',
      title: 'Preparing download',
      description: `Control unit file download has been requested`,
      indeterminate: true,
    });

    return app.store.api.pilelist
      .download_control_unit_file(project_id, pilelist_id, params)
      .then(async (response) => {
        const { headers, data } = response;
        let file_name = null;
        const disposition = headers['content-disposition'];

        if (disposition && disposition.indexOf('attachment') !== -1) {
          let pattern = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = pattern.exec(disposition);
          if (matches != null && matches[1]) {
            file_name = matches[1].replace(/['"]/g, '');
          }
        }

        await AcDownloadFile(data, file_name, false);

        app.store.toasters.update(toast.id, {
          title: 'Download started',
          description: `Control unit file has been downloaded`,
          variant: 'success',
          indeterminate: false,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        app.store.toasters.update(toast.id, {
          title: null,
          description: `Failed to download Control unit file`,
          code: AcFormatErrorCode(error),
          variant: 'error',
          indeterminate: false,
        });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  validate_piles = async (project_id, pilelist_id, pile_id, data) => {
    return app.store.api.pilelist
      .validate_piles(project_id, pilelist_id, pile_id, data)
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  update_piles = async (project_id, pilelist_id, data) => {
    return app.store.api.pilelist
      .update_piles(project_id, pilelist_id, data)
      .then(async (response) => {
        app.store.toasters.add({
          variant: 'success',
          description: `Changes have been saved.`,
        });

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to update the piles',
            description: AcFormatErrorMessage(error),
          });

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  delete_piles = async (project_id, pilelist_id, pilelist_name, params) => {
    const toast = await app.store.toasters.add({
      variant: 'delete',
      title: 'Preparing to delete piles',
      description: `Deleting <strong>${params.piles.length} pile${
        params.piles.length === 1 ? '' : 's'
      }</strong> from pile list <strong>${pilelist_name}</strong>`,
      indeterminate: true,
    });

    return app.store.api.pilelist
      .delete_piles(project_id, pilelist_id, params)
      .then(async (response) => {
        app.store.toasters.update(toast.id, {
          title: 'Piles deleted',
          description: `<strong>${params.piles.length} pile${
            params.piles.length === 1 ? '' : 's'
          }</strong> deleted from pile list <strong>${pilelist_name}</strong>`,
          variant: 'success',
          indeterminate: false,
        });
        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.update(toast.id, {
            title: null,
            description: `Failed to delete the selected piles`,
            code: AcFormatErrorCode(error),
            variant: 'error',
            indeterminate: false,
          });

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  set = (target, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  setState = (target, property, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (!AcIsSet(property)) return;
    if (AcIsUndefined(value)) return;

    this[target][property] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };
}

export default PileListStore;
